<template>
  <div class="content-container">
    <PageTitle :title="$t('MY_CLASSES.TITLE')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('CREATE_CLASS.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_TEACHER_PORTAL.MY_CLASSES"/>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>

        <!-- Name -->
        <InputClassName
          :on-input="nameUpdated"
          :accepted="(value) => nameAvailable = value" />


        <!-- Teacher -->
        <DropdownWithCheckboxes id="teachers"
                                :field-title="$t('EDIT_CLASS.TEACHERS')"
                                :dropdown-options="teachers.filter(value => value.name)"
                                :placeholder="$t('EDIT_CLASS.TEACHERS_PLACEHOLDER')"
                                :field-name="'teachers'"
                                :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                                :callback="getSelectedTeacherIds"
                                :api-error-text="''"
                                :input-icon="require('../../../assets/icons/icn_account.svg')"
                                class="form-element"/>


        <!-- Group -->
        <Dropdown id="group"
                  :field-title="isSpecialClass ? $t('GROUP_COGNITIVE') : $t('GROUP')"
                  :field-text="isSpecialClass ? $t('EDIT_CLASS.COGNITIVE_LEVEL_DESCRIPTION') : ''"
                  :dropdown-options="groups"
                  :placeholder="$t('EDIT_CLASS.GROUP_PLACEHOLDER')"
                  :field-name="'group'"
                  :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                  :input-icon="require('../../../assets/icons/icn_friends.svg')"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>

        <div class="two-column-wrapper">
          <!-- Total Students -->
          <InputField :field-title="$t('TOTAL_STUDENTS')"
                      :field-name="'totalStudents'"
                      :rules="'required'"
                      :type="'number'"
                      :placeholder="$t('TOTAL_STUDENTS')"
                      :cy-selector="'students-input'"
                      :max="40"
                      :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                      :api-error-text="''"
                      :input-icon="require('../../../assets/icons/icn_friends.svg')"
                      class="form-element-column"/>

          <!-- Accessability Type -->
          <Dropdown id="accessibilityType"
                    :field-title="$t('EDIT_CLASS.ASSISTANCE')"
                    :dropdown-options="accessibilityOptions"
                    :placeholder="$t('ASSISTANCE.REGULAR')"
                    :field-name="'accessibilityType'"
                    :input-icon-error="require('../../../assets/icons/icn_error.svg')"
                    :callback="accessibilityTypeUpdate"
                    :selected-option="selectedAccessibilityType"
                    :api-error-text="''"
                    :input-icon="require('../../../assets/icons/icn_gebarentaal.svg')"
                    class="form-element-column"/>

        </div>

        <!-- Sound settings-->
        <ToggleSwitch :field-title="'Extra geluiden in de MediaMissies'"
                      :field-name="'hasExtraSounds'"
                      :value="hasExtraSounds"
                      :callback="onSoundsToggle"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('CREATE_CLASS_TEXT') }}
        </button>
      </template>

    </FormWrapper>

    <BasicModal v-if="true" class="class-popup"/>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import DropdownWithCheckboxes from '@/components/yo-form/DropdownWithCheckboxes'
import Dropdown from '@/components/yo-form/Dropdown'
import ToggleSwitch from '@/components/yo-form/ToggleSwitch'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import { computed, ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { CREATE_SCHOOL_CLASS, GET_SCHOOL_CLASSES } from '@/store/modules/teacherPortal/actions'
import router from '@/router'
import nl from '@/utils/language/nl.json'
import InputClassName from '@/components/elements/inputFields/InputClassName'
import { SET_TOAST_DATA } from '@/store/mutations'
import {SET_SELECTED_CLASS} from "@/store/modules/teacherPortal/mutations";
import { GET_CLASS_TYPES } from '@/store/actions'

import BasicModal from '@/components/elements/basicModal/BasicModal'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'

export default {
  name: 'CreateClass',
  components: {
    BackButton,
    PageTitle,
    InputField,
    FormWrapper,
    DropdownWithCheckboxes,
    Dropdown,
    InputClassName,
    ToggleSwitch,
    BasicModal
  },
  setup() {
    const { t } = useI18n({})
    const store = useStore()
    store.dispatch(GET_CLASS_TYPES);

    // TODO: These are pretty much the same with EditClass.vue. When the API is final, we could merge them in a mixin.
    /** Teachers Dropdown **/ // TODO: Get from the API.
    const formTeacherIds = ref([])
    const teachers = ref([])
    /** Education Types **/
    const educationTypes = ref([])
    const isSpecialClass = computed(() => store.getters.getTeacherProfile.isSpecialEducationSchool)

    /** Group Dropdown **/
    const groups = computed(() => {
      const classTypes = store.getters.getClassTypes;
      const sorted = classTypes?.sort((a, b) => {
        if (a.name.match(/^\d/) && !b.name.match(/^\d/)) {
          return 1
        }
        if (!a.name.match(/^\d/) && b.name.match(/^\d/)) {
          return -1
        }
        return a.name.localeCompare(b.name)
      })
      return sorted
    });


    const nameUsedGlobally = ref(false)

    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    
    const classData = ref(null);

    Promise.all([
      ApiHandler.get('teacher/get-teachers-of-school'),
    ]).then((result) => {
      teachers.value = result[0].data
    })

    function getSelectedTeacherIds(teachers) {
      formTeacherIds.value = teachers.map(teacher => teacher.id)
    }

    const classes = computed(() => store.getters.getSchoolClasses.map(x => x.name))

    const currentName = ref('')
    const nameAvailable = ref(true)
    function nameUpdated(value) {
      currentName.value = value
    }

    getClasses()
    function getClasses(payload) {
      return store.dispatch(GET_SCHOOL_CLASSES, payload)
    }

    const nameInUse = computed(() =>
      classes.value.some(x => x.toLowerCase() === currentName.value.toLowerCase()) ||
      nameUsedGlobally.value
        ? nl.CREATE_CLASS.NAME_IN_USE
        : '')

    /** Accessability Type **/
    const accessibilityOptions = [
      { id: 1, name: t('ASSISTANCE.REGULAR') },
      { id: 2, name: t('ASSISTANCE.SIGN_LANGUAGE') },
      { id: 3, name: t('ASSISTANCE.AUDIO_DESCRIPTION') },
      { id: 4, name: t('ASSISTANCE.SUBTITLES') }]
    const selectedAccessibilityType = ref(accessibilityOptions[0])

    function accessibilityTypeUpdate(selected) {
      // console.log('selected', selected);
      selectedAccessibilityType.value = selected
    }

    const hasExtraSounds = ref(true)
    function onSoundsToggle(newToggleVal) {
      hasExtraSounds.value = newToggleVal
    }

    /** Form **/
    function validateData(data) {
      // Get the teacher ids.
      if (nameInUse.value !== '') {
        return
      }
      data.teacherIds = formTeacherIds.value
      data.accessibilityTypeId = selectedAccessibilityType.value.id
      data.classTypeId = reverseLookup(groups.value, data.group)
      classData.value = data;

      if(!isSpecialClass.value) {
        createNewClass()
        return;
      }

      if (data.classTypeId !== 5) {
        modalController.value.setModal(BASIC_MODAL_CONTENT.CREATE_SPECIAL_CLASS, onSubmit, null, null, MODAL_NAMES.CREATE_SPECIAL_CLASS)
        return
      }
      modalController.value.setModal(BASIC_MODAL_CONTENT.CREATE_SPECIAL_CLASS_2, onSubmit, null, null, MODAL_NAMES.CREATE_SPECIAL_CLASS_2)
    }
    
    function createNewClass() {
      createClass(classData.value).then(response => {
        if (response.error) {
          if (response.error === 'school_class_name_already_in_use_exception') {
            nameUsedGlobally.value = true
          }
        } else if (response) {
          store.commit(SET_TOAST_DATA, { text: `Schoolklas "${response.data.name}" succesvol aangemaakt`, src: require('@/assets/icons/icn_successful.svg') })
          store.commit(SET_SELECTED_CLASS, response.data)
          router.push({ name: ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW })
        }
      })
    }

    function onSubmit() {
      modalController.value.resetActiveModal();
      createNewClass();
    }

    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }

    /** Create Class **/
    function createClass(data) {
      return store.dispatch(CREATE_SCHOOL_CLASS, data)
    }

    return {
      ROUTE_NAMES_TEACHER_PORTAL,

      /** Teachers Dropdown **/
      teachers,
      getSelectedTeacherIds,

      /** Education Types **/
      isSpecialClass,
      educationTypes,

      /** Group Dropdown **/
      groups,

      /** Accessibility Type **/
      accessibilityOptions,
      selectedAccessibilityType,
      accessibilityTypeUpdate,

      /** Sounds **/
      hasExtraSounds,
      onSoundsToggle,

      /** Form **/
      validateData,
      nameInUse,
      nameUpdated,
      nameAvailable,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

:deep(.class-popup) {
  .basic-modal-subtext {
    text-align: left;
  }

  .align-center {
    text-align: center;
    display:table;
  }

  li {
    padding-left: rem(24);
    list-style-type: '- '
  }
}

:deep(.form-element) {
  position: relative;
}

.two-column-wrapper {
  margin-bottom: rem(32);
}

.divider {
  background-color: var(--purple_light);
  border: none;
  height: rem(2);
  opacity: 30%;
  margin: rem(40) 0;
}

</style>
